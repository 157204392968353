import Vue from "vue";
import VueRouter from "vue-router"; 

import Layout from '../App.vue'
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/home",
    component: Layout,
    children: [
      {
        path: "home",
        component: () => import("@/views/home/index.vue"),
        name: "Home",
      },
      
      {
        path: "spatial",
        component: () => import("@/views/spatial/index.vue"),
        name: "Spatial",
      },
      {
        path: "broadcast",
        component: () => import("@/views/broadcast/index.vue"),
        name: "Broadcast",
      },
      
      {
        path: "ruralPractitioner",
        component: () => import("@/views/ruralPractitioner/index.vue"),
        name: "ruralPractitioner",
      }
    ],
  },
];

const router = new VueRouter({
  routes: routes,
});
export default router;
